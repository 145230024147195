<template >

  <!-- Maintenance mode WEBSITE -->
  <maintenance-mode-window v-if="$store.state.MAINTENANCE_ON" />
  <!-- AVALON WEBSITE -->
  <businessPublicPage
      v-else-if="displayAvalon"
      :alias="alias"
      :pageAlias="pageAlias"
      :news-alias="newsAlias"
      :document-check="documentCheck"
      :course="course"
  />

  <!-- DEFAULT WEBSITE TEMPLATE -->
  <default-site
      v-else-if="!loading"
      :course="course"
      :alias="alias"
  />


</template>

<script>

import {mapActions, mapState} from "vuex";
import referral from "@/mixins/referral";
import maintenanceModeWindow from "@/store/maintenanceModeWindow";
import DefaultSite from "@/components/AvalonEditor/defaultSite/DefaultSite";
import businessPublicPage from "@/components/AvalonEditor/BusinessPublicPage";



export default {
  name: "TemplateLoader",
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      titleTemplate: this.$store.state.business.selectedBusiness.name ? `%s | ${ this.$store.state.business.selectedBusiness.name } ` :   `%s | WeStudy`,
    }
  },
  props : ['alias','pageAlias' , 'newsAlias' , 'documentCheck' , 'course'],
  mixins : [referral],
  components : {
    businessPublicPage,
    maintenanceModeWindow,
    DefaultSite
  },
  data(){
    return {
      displayAvalon : false,

      loading : true,

      template : null,
      business : null,
    }
  },
  computed : {
    ...mapState('templates',  [
      'editorMode',

      'displayEditLogo',
      'displayEditBlock',
      'displayEditMainMenu',
      'displayEditColumn',

      'templateLoaded' ,
      'openEditor',
      'openEditorGeneral',
      'openEditorBlockElement',
      'displayNewPageDialog',
      'editorTrigger'
    ]),


  },
  watch : {
    editorTrigger() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('business',  [ 'GET_BUSINESS' ]),
    prepareBusinessData(out) {
      this.$store.state.templates.selectedBusiness = out.data;
      this.$store.state.business.selectedBusiness = out.data;
      this.$store.state.templates.businessAlias = out.data.alias;
      this.$store.state.business.selectedBusiness.alias = out.data.alias;
      this.$store.state.business.selectedBusiness.modules = out.data.modules;
      this.template = out.data.template
      this.$store.state.templates.templateAlias = out.data.template
      this.business = out.data.alias
      this.$store.state.templates.businessName = out.data.name;
      this.$store.state.templates.businessTemplate = out.data.template;
      this.$store.state.templates.products = out.data.products
      this.$store.state.templates.product_categories = out.data.product_categories
      this.$store.state.templates.news.reverse()
      this.$store.state.templates.locations = out.data.locations
      this.$store.state.templates.deliveryAllowCountries = out.data.deliveryAllowCountries
      this.$store.state.templates.businessCountry = out.data.country
      this.$store.state.templates.deliveryCountries = out.data.deliveryCountries
      this.$store.state.business.selectedBusiness.currency = out.data.currency;


      if ( out.data.products ) {
        this.$store.state.templates.rentedProducts = out.data.products.filter(el=> el.parentProduct)
      }


      if ( out.data.deliveries) {
        this.$store.state.cart.deliveries = []
        out.data.deliveries.forEach( (delivery)=>{
          this.$store.state.cart.deliveries.push({text:delivery.name,value:delivery.id})
        })
      }

      if (out.data.payments) {
        this.$store.state.cart.payments = []
        out.data.payments.forEach( (payment)=>{
          this.$store.state.cart.payments.push({text:payment.name,value:payment.id})
        })
      }

    },
    prepareBusinessTemplate(out,editorMode) {

        let objectType = editorMode ? 'templateVars_current' : 'templateVars_init'
        this.$store.state.templates[ objectType ] = out.data.template_object

    },
    initPage() {
      this.loading = true;

      let editorMode = this.$route.path.includes('/edit')
      this.$store.state.templates.editorMode = editorMode

      this.GET_BUSINESS( this.$store.state.domain ? location.host : this.alias ) . then((out)=>{

        if ( out.data ) {
          if ( out.data.disable_public ) {
            this.prepareBusinessData( out )
            this.$router.push(this.businessDashLink(''))
            return
          }

          if ( out.data.avalon ) {
            this.displayAvalon = true
            return
          }
        } else {
          if ( out === 'maintenance_mode_on' ) {
            this.$store.state.MAINTENANCE_ON = true
          } else {
            this.$store.state.MAINTENANCE_ON = false
          }
        }


        this.prepareBusinessData( out )
        this.prepareBusinessTemplate( out , editorMode )
        this.checkBusinessReferral()

        this.loading = false;

      }).catch((  )=>{
        this.loading = false;
      })
    }
  },
  beforeMount() {
    this.$store.state.avalon.sideMenu = false
    this.$store.state.avalon.editorMode = false
    this.initPage()
  }
}
</script>

<style scoped>
.loadingTemplate {
  position : absolute;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
}
</style>